import { delay, firstValueFrom, of } from 'rxjs';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStoreService } from '../global-store/global-store.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { UserService } from '../user/user.service';
import { WebSocketService } from '../websocket/websocket.service';

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  private initalizedStartedMs?: number = undefined;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly localStorageService: LocalStorageService,
    private readonly globalStoreService: GlobalStoreService,
    private readonly userService: UserService,
    private readonly websocketService: WebSocketService
  ) {}

  async init(): Promise<void> {
    const path = this.location.path();
    const startedMs = Date.now();

    if (this.initalizedStartedMs !== undefined) {
      return;
    }

    this.initalizedStartedMs = startedMs;

    if (path !== '' && !path.startsWith('/auth') && !path.startsWith('/loading') && !path.startsWith('/errors')) {
      this.localStorageService.set('redirectUri', path);
    }

    await this.router.navigate(['/loading'], { skipLocationChange: true });

    if (!this.userService.getAuthToken()) {
      this.router.navigate(['/auth/login-qr']);
    } else {
      await Promise.all([firstValueFrom(of('').pipe(delay(1500)))]);
    }

    // prevent multiple navigation calls if user toggles the visibility of the app
    if (document.visibilityState === 'visible' && this.initalizedStartedMs === startedMs) {
      this.navigateToNextScreen();
      if (this.globalStoreService.isProvisioningComplete.value) {
        await this.websocketService.connectSocket();
      }
    }
  }

  resetInitialization(): void {
    this.initalizedStartedMs = undefined;
  }

  resetGlobalStoreValues() {
    this.globalStoreService.deviceId.next(undefined);

    this.globalStoreService.vehicleType.next(undefined);
    this.globalStoreService.vehicleModel.next(undefined);
    this.globalStoreService.vehicleVendor.next(undefined);
    this.globalStoreService.licencePlate.next(undefined);

    this.globalStoreService.camToFront.next(undefined);
    this.globalStoreService.camToGround.next(undefined);
    this.globalStoreService.camToLeft.next(undefined);
    this.globalStoreService.camToRight.next(undefined);
    this.globalStoreService.camToRearAxle.next(undefined);
    this.globalStoreService.widthOfVehicle.next(undefined);
    this.globalStoreService.tireDiameter.next(undefined);
    this.globalStoreService.measurementUnit.next(undefined);

    this.globalStoreService.isDeviceSetupComplete.next(undefined);
    this.globalStoreService.isProvisioningComplete.next(undefined);
    this.globalStoreService.isCalibrationComplete.next(undefined);
    this.globalStoreService.isPairButtonComplete.next(undefined);

    this.globalStoreService.isTokenRefreshing.next(undefined);
  }

  logout() {
    this.websocketService.close();
    this.resetInitialization();
    this.resetGlobalStoreValues();

    if (this.location.path().startsWith('/calibration/camera')) {
      setTimeout(() => {
        this.userService.removeAuthentication();
      }, 2500);
    } else {
      this.userService.removeAuthentication();
    }
    this.router.navigate(['/auth/login-qr']);
  }

  private navigateToNextScreen(): void {
    const redirectUri = this.localStorageService.get('redirectUri', true);
    if (redirectUri !== null) {
      this.router.navigateByUrl(redirectUri);
      this.localStorageService.remove('redirectUri');
    } else {
      this.router.navigate(['/home/landing-page']);
    }
  }
}
