import { catchError, map, Observable, of, tap } from 'rxjs';
import { Oauth2Response } from 'src/app/models/user';
import awsMobileDev from 'src/configs/aws/aws-exports-dev';
import awsMobileLocal from 'src/configs/aws/aws-exports-local';
import awsMobileProd from 'src/configs/aws/aws-exports-prod';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { GlobalStoreService } from '../global-store/global-store.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly globalStoreService: GlobalStoreService
  ) {}

  getAuthToken(): string | null {
    const authToken: string | null = this.localStorageService.get('authToken');
    return authToken;
  }

  authenticate(): Observable<string | null> {
    const authToken: string | null = this.getAuthToken();
    if (authToken) {
      return of(authToken);
    } else {
      return this.fetchToken().pipe(
        catchError((err) => {
          console.log(`Error during client credentials authentication: ${err}`);
          this.localStorageService.remove('authToken');
          return of(null);
        })
      );
    }
  }

  removeAuthentication(): void {
    this.localStorageService.remove('authToken');
  }

  private fetchToken(): Observable<string | null> {
    let clientId: string;
    let clientSecret: string;

    if (environment.production) {
      clientId = awsMobileProd.aws_user_pools_web_client_id;
      clientSecret = awsMobileProd.aws_user_pools_web_client_secret;
    } else if (environment.development) {
      clientId = awsMobileDev.aws_user_pools_web_client_id;
      clientSecret = awsMobileDev.aws_user_pools_web_client_secret;
    } else {
      clientId = awsMobileLocal.aws_user_pools_web_client_id;
      clientSecret = awsMobileLocal.aws_user_pools_web_client_secret;
    }

    const encodedClientIdAndSecret = btoa(`${clientId}:${clientSecret}`);
    const body: HttpParams = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', clientId)
      .set('client_secret', clientSecret)
      .set('scope', `${environment.cognitoAuthUrl}/no.validation`);

    return this.http
      .post<Oauth2Response>(`${environment.cognitoAuthUrl}/oauth2/token/`, body, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', `Basic ${encodedClientIdAndSecret}`),
      })
      .pipe(
        tap((response: Oauth2Response) => {
          this.localStorageService.set('authToken', response.access_token);
          this.globalStoreService.isTokenRefreshing.next(false);
        }),
        map((response: Oauth2Response) => response.access_token)
      );
  }
}
