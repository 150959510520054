import { Observable, takeUntil } from 'rxjs';
import { HeaderService } from 'src/app/services/header/header.service';
import { StartUpService } from 'src/app/services/startup/startup.service';
import { UserService } from 'src/app/services/user/user.service';
import { downloadLicenseDisclosureDocument } from 'src/app/utils/utils';
import { Location } from '@angular/common';
import { Component, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent {
  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.profileMenuVisible = false;
      this.imprintMenuVisible = false;
    }
  }

  headerTitle = '';
  hasAuth?: boolean;
  imprintMenuVisible = false;
  profileMenuVisible = false;

  showBackButton$?: Observable<boolean | undefined>;

  downloadLicenseDisclosureDocument = downloadLicenseDisclosureDocument;

  constructor(
    private readonly eRef: ElementRef,
    private readonly router: Router,
    private readonly location: Location,
    private readonly translate: TranslateService,
    private readonly headerService: HeaderService,
    private readonly startUpService: StartUpService,
    private readonly userService: UserService
  ) {
    super();
    this.getCurrentAuth();

    this.headerService
      .getTitle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((title: string) => {
        this.headerTitle = title;

        this.translate
          .get([title])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((translatedValue) => (this.headerTitle = translatedValue[title].headerTitle));

        this.getCurrentAuth();
      });

    this.showBackButton$ = this.headerService.showBackButtonObservable();
  }

  back(): void {
    const backUrl = this.headerService.backButtonUrl;

    if (backUrl) {
      if (backUrl.startsWith('https')) {
        window.location.href = backUrl;
      } else {
        this.router.navigate([backUrl]);
      }
      return;
    }

    this.location.back();
  }

  async onLogoutClick(): Promise<void> {
    this.hasAuth = undefined;
    this.startUpService.logout();
  }

  private getCurrentAuth(): void {
    this.hasAuth = this.userService.getAuthToken() !== null;
  }
}
