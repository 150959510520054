/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:21068fdd-2d6f-4b30-809b-91f0cae03503',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_Oc2WZsLqc',
  aws_user_pools_web_client_id: 'lkhik932ov7d52t6b2p8ckc4',
  aws_user_pools_web_client_secret: '1lene156kjiv6ve278fj5b7il3hmicmh5df2mc3792irlnrruthh',
  oauth: {
    domain: 'dev-ridecare.auth.eu-central-1.amazoncognito.com',
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'http://localhost:4200/home',
    redirectSignOut: 'http://localhost:4200/auth/login',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

export default awsmobile;
