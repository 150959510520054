/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:2bf69a44-1629-45b9-a9be-f3e7c9aa9ee3',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_5oSMCSEoP',
  aws_user_pools_web_client_id: '20eed80f8of1750bchlf9btv85',
  aws_user_pools_web_client_secret: '11fllh6mqro06ogmv9831a55irsddua60pja7g3n5cvbv96nqds',
  oauth: {
    domain: 'prod-ridecare.auth.eu-central-1.amazoncognito.com',
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://viper.bosch-ridecare.com/home',
    redirectSignOut: 'https://viper.bosch-ridecare.com/auth/login',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

export default awsmobile;
