/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  filter,
  merge,
  Observable,
  Subject,
  Subscription,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { STATUS_CODE, WsBaseResponse } from 'src/app/models/websocket';
import { BaseCommand, HeartbeatCommand, SubscribeTopicCommand } from 'src/app/models/websocket-commands';
import { CommandType, ConnectionStatus, EventType, TopicType } from 'src/app/models/websocket-enums';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { GlobalStoreService } from '../global-store/global-store.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  // The subject used to create and manage the websocket connection
  socket$?: WebSocketSubject<WsBaseResponse>;

  // This Subject listens for the messages sent by the server
  private messagesSubject$ = new Subject<WsBaseResponse>();
  // The observable with the messagesSubject$ as source (the observable with the messages from the server)
  messages$ = this.messagesSubject$.asObservable();

  // This subject is used to track the connection status of the websocket connection
  private connectionStatus = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.CLOSE);
  // The observable with the connectionStatus$ as source (the observable witch tracks websocket connection)
  connectionStatus$ = this.connectionStatus.asObservable();

  networkError = false;
  private deviceId = 'unknown';
  private heartbeatSubscription?: Subscription;

  constructor(
    private readonly globalStoreService: GlobalStoreService,
    private readonly userService: UserService
  ) {
    this.globalStoreService.isProvisioningComplete.subscribe({
      next: (complete: boolean | undefined) => {
        if (complete) {
          this.connectIfReady(this.globalStoreService.deviceId.value);
        }
      },
    });
  }

  /**
   * Creates a socket connection if a device id is available
   * and creates a new connection if the device id is changed
   * @private
   * @param {(string | undefined)} deviceId
   */
  private async connectIfReady(deviceId: string | undefined) {
    if (deviceId) {
      if (this.deviceId !== deviceId) {
        this.deviceId = deviceId;

        if (this.socket$ && !this.socket$.closed) {
          this.socket$.complete();
        }
      }
      await this.connectSocket();
    }
  }

  /**
   * Creates a new socket connection and updates the socket message values subject
   * It will not create a new socket connection if the browser tab is not active
   * and will throw and error if no device id is available
   * @return {*}  {Promise<void>}
   */
  async connectSocket(): Promise<void> {
    if (document.visibilityState === 'hidden' || !navigator.onLine) {
      return;
    }

    const deviceId = this.globalStoreService.deviceId.value;

    if (deviceId) {
      this.deviceId = deviceId;
    } else {
      // This is an error that should never occur while the route guards are active.
      throw new Error('Missing device ID');
    }

    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = await this.getNewWebSocket();
      this.socket$?.pipe(catchError(() => EMPTY)).subscribe({
        next: (response) => {
          this.messagesSubject$.next(response);
        },
      });
    }
  }

  private async getNewWebSocket() {
    let token: string | null = this.userService.getAuthToken();

    if (!token) {
      token = 'no-token';
    }

    return webSocket<WsBaseResponse>({
      url: `${environment.websocketUrl}?deviceId=${this.deviceId}`,
      protocol: ['access-token', token],
      openObserver: {
        next: () => this.onConnectionOpen(),
      },
      closeObserver: {
        next: (event) => this.onConnectionClose(event),
      },
    });
  }

  private onConnectionOpen() {
    if (this.networkError) {
      window.location.reload();
    }

    this.noConnection(false);

    const subscribeToConnectionStatus: SubscribeTopicCommand = {
      type: CommandType.SUBSCRIBE_TOPIC,
      topic: TopicType.CONNECTION_STATUS,
    };

    this.sendMessage(subscribeToConnectionStatus);

    this.connectionStatus.next(ConnectionStatus.OPEN);
    this.heartbeatSubscription = this.heartbeatObservable().subscribe();
  }

  /**
   * Send request to the websocket server
   */
  sendMessage(request: BaseCommand): void {
    if (this.socket$ && !this.socket$.closed) {
      request.deviceId = this.deviceId;
      this.socket$.next(request);
    }
  }

  private noConnection(show: boolean) {
    if (show) {
      this.networkError = true;
      return;
    }

    this.networkError = false;
  }

  private onConnectionClose(event: CloseEvent) {
    // eslint-disable-next-line no-console
    console.log('Websocket closed with:', event.code, STATUS_CODE[event.code]);
    this.socket$?.complete();
    this.socket$ = undefined;
    this.connectionStatus.next(ConnectionStatus.CLOSE);
    this.heartbeatSubscription?.unsubscribe();
    if (document.visibilityState === 'visible' && navigator.onLine && this.globalStoreService.deviceId.value) {
      // the current token expires after 5 minutes
      // to prevent WS reconnection using the old token,
      // the old token is deleted and a new one is fetched
      if (!this.globalStoreService.isTokenRefreshing.value) {
        this.userService.removeAuthentication();
        this.userService.authenticate().subscribe();
      }

      setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log('Websocket tries to reconnect');
        this.connectSocket();
      }, 5000);
    }
  }

  private heartbeatObservable() {
    let sendHeartbeat = true;

    return merge(timer(15000, 15000), this.observeMessagesByEventType<WsBaseResponse>(EventType.HEARTBEAT)).pipe(
      takeWhile((res) => {
        if (typeof res === 'number' && !sendHeartbeat) {
          this.socket$?.complete();
          this.noConnection(true);
          return false;
        }

        return true;
      }),
      tap(() => {
        if (sendHeartbeat) {
          const heartbeatRequest = {
            type: CommandType.HEARTBEAT,
            correlationId: new Date().getTime(),
          } as HeartbeatCommand;
          this.sendMessage(heartbeatRequest);
        }

        sendHeartbeat = !sendHeartbeat;
      })
    );
  }

  close(): void {
    this.socket$?.complete();
  }

  observeMessagesByEventType<T extends WsBaseResponse>(type: EventType): Observable<T> {
    return this.messages$.pipe<any>(filter((baseEvent) => baseEvent.type === type));
  }
}
